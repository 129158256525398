<div ngxBraintreeDirective>
  <div id="dropin-container"></div>

  <div #buttonsRef>
    <ng-content select=".ngxButtons"></ng-content>
  </div>

  <!-- No buttons are projected and no styles sent, so the default button (Purchase) with default styles will be used. -->
  <div class="action-button-wrapper mt-3 text-center text-lg-start">
    <span *ngIf="buttonsRef.children.length === 0 && showPayButton">
      <button data-auto-id="paymentBtn" class="btn btn-info btn-lg px-5 wdthfix" (click)="Pay()">
        Confirm & Pay <i class="fa fa-chevron-right pl-2"></i>
      </button>     
    </span>

  </div>

  <p-dialog
    header="Alert"
    [(visible)]="errorMessage"
    [modal]="true"
    [closable]="false"
    [dismissableMask]="false"
  >
    <p>
      {{errorMessage}}
    </p>
    <p-footer>
      <button class="btn btn-info btn-lg px-5 wdthfix" (click)="hidePaymentAlert()">
        Ok
      </button>    
    </p-footer>
  </p-dialog>

  <p-dialog
    header="Alert"
    [(visible)]="unsuccessfulMessage"
    [modal]="true"
    [closable]="false"
    [dismissableMask]="false"
  >
    <p>
      {{ unsuccessfulMessage }}
    </p>
    <p-footer>
      <button class="btn btn-info btn-lg px-5 wdthfix" (click)="hideUnsuccessfulAlert()">
        Ok
      </button>    
    </p-footer>
  </p-dialog>
</div>
