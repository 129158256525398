export const environment = {
  production: true,
  envName: 'prod',
  autoClub: 'RAC',
  veriskNetworkUsername: '',
  veriskNetworkPassword: '',
  addressify: {
    baseUrl: 'https://api.addressify.com.au',
    apiKey: '0E4644FE-85E4-4685-930F-DBF5F8629DFA',
  },
  baseAPIUrl:
    'https://ca-qa-tmma-ltx-qj-api.icymeadow-5a31b099.australiaeast.azurecontainerapps.io/api/',
  verisk: {
    username: 'BB3TokioMarineRACAusTravelUAT',
    vrrInternal:
      'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFJBQyBBdXMgVHJhdmVsIEJCMyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkJsYWNrQm94Q3VzdG9tZXIiLCJjbGllbnRJZCI6IjkwIiwiaXNzIjoiQkIuTWFpbnRlbmFuY2VUb29sLlRlc3QiLCJhdWQiOiJWUlIuQkIzLlVBVCJ9.on3bhXonuWyeeMq6iusOi3kO10_AP7LR9N74KjMf2w0',
  },
  DMSAPI: {
    baseUrl: 'https://dmsapiservices.azurewebsites.net/',
    apiKey: 'A3886FC2E95C962113161EBBC6F6E',
  },
  PASUrl: 'https://pasqa.tmaainsurance.com',
};
