<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header [activeHeader]="'FAQs'"></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/rac/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Insurance FAQs</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info"></div>

          <div class="page-content-info">
            <!-- Accordion -->
            <div class="card mb-3 shadow border-0" style="border-radius: 40px">
              <div class="faqs-accorion accordion" id="faqsAccordion">
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title rounded-top-10"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_01"
                      (click)="toggleCollapse($event, 'faqsContent_01')"
                    >
                      Why should I consider getting travel insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_01"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Travel Insurance can provide some protection when
                          unforeseen events occur whilst you are travelling and
                          can help cover unexpected expenses. Travel Insurance
                          can provide some cover for things like theft,
                          accidents, or medical problems. To find out more about
                          the benefits of travel insurance, visit the Department
                          of Foreign Affairs and Trade website.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_02"
                      (click)="toggleCollapse($event, 'faqsContent_02')"
                    >
                      What are the consequences of travelling without travel
                      insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_02"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <div class="">
                          <p>
                            Without travel insurance, you could be liable for
                            thousands of dollars in medical bills,
                            transportation costs in the case of cancelled
                            flights, or the cost of replacing your personal
                            valuables if your luggage is lost or stolen.
                          </p>
                          <p>
                            The Australian Government handles more than 20,000
                            cases of Australians in difficulty overseas every
                            year (check out
                            <a
                              href="http://smartraveller.gov.au/Pages/default.aspx"
                              >Smartraveller's</a
                            >
                            informative guide to travel insurance) – that’s why
                            buying travel insurance should be an essential item
                            on your pre-holiday to-do list.
                          </p>
                          <p>
                            If you can't afford travel insurance, you can't
                            afford to travel.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_03"
                      (click)="toggleCollapse($event, 'faqsContent_03')"
                    >
                      Where can I find Coronavirus (COVID-19) travel insurance
                      information?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_03"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          For information regarding Coronavirus (COVID-19) and
                          your RAC travel policy, please refer to
                          <a
                            rel="noopener noreferrer"
                            href="https://rac.tmmatravel.com.au/travel-alerts/New-COVID-19-FAQs"
                            target="_blank"
                            >these frequently asked questions</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_04"
                      (click)="toggleCollapse($event, 'faqsContent_04')"
                    >
                      What does RAC's travel insurance cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_04"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          All of RAC's Travel Insurance International policies
                          provide 24-hour medical emergency assistance and
                          unlimited reasonable overseas medical treatment
                          (conditions, sub-limits and exclusions apply). We
                          offer a number of policy options with varying benefits
                          and limits for both International travel and Domestic
                          travel, as well as a domestic rental car excess only
                          policy. The policy also covers your listed Dependents
                          such as children, grandchildren, step-children and
                          foster children at no extra cost, provided they are
                          travelling with you, are up to the age of 25, are
                          financially dependent on their parents or
                          grandparents, are not working full time, and do not
                          require medical assessment. Please refer to the table
                          of benefits in the
                          <a
                            href="/travel-touring/travel-insurance/product-disclosure-statement"
                            >Product Disclosure Statement</a
                          >
                          for further details.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_05"
                      (click)="toggleCollapse($event, 'faqsContent_05')"
                    >
                      What does travel insurance not cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_05"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-3">
                        <p>
                          World2Cover travel insurance does not cover every
                          event and circumstance and there may also be limits to
                          those we do cover. We have listed exclusions that
                          apply to all our travel insurance options in either
                          the specific section and/or the
                          <a href="{{ pdsLink }}" target="_blank"
                            >General Exclusion section of our Product Disclosure
                            Statement</a
                          >.
                        </p>
                        <p>
                          There is a Benefits table for each of the specific
                          levels of cover as well as for the policy options and
                          add-ons.
                        </p>
                        <p>
                          For the full list of exclusions and the benefit limits
                          and sublimits, please read the
                          <a href="{{ pdsLink }}" target="_blank"
                            >Product Disclosure Statement</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_06"
                      (click)="toggleCollapse($event, 'faqsContent_06')"
                    >
                      Who is the insurer for World2Cover travel insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_06"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          World2Cover Travel Insurance is issued by Tokio Marine
                          &amp; Nichido Fire Insurance Co., Ltd (Tokio Marine
                          &amp; Nichido). ABN 80 000 438 291, AFSL 246548 is the
                          insurer and issuer of this policy and PDS. We also
                          have an APRA authorisation to conduct general
                          insurance business in Australia.
                        </p>
                        <p>
                          Our managing agent and representative, Tokio Marine
                          Management Australia Pty. Ltd. ABN 69 001 488 455
                          (TMMA), is authorised under a binder and managing
                          agent agreement to act on our behalf to issue our
                          policies and handle and settle claims in relation to
                          those policies, subject to the terms of the authority.
                        </p>
                        <p>
                          As well as Travel Insurance, Tokio Marine &amp;
                          Nichido also work with the general insurance market
                          through insurance brokers and provide insurance for
                          commercial and corporate businesses in Australia since
                          1963. Tokio Marine &amp; Nichido was founded in 1879
                          in Japan, operates in multiple countries and employees
                          thousands of people worldwide.
                        </p>
                        <p>
                          Our Australian Head Office is based in Sydney as per
                          our Call Centre, Sales and Claims department.
                          World2Cover is an awarded travel insurance provider by
                          Canstar in 2022 and from 2016 to 2019 for Outstanding
                          Value International Travel Insurance and by Mozo for
                          Travel Insurance of the Year in 2018 and 2019.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_07"
                      (click)="toggleCollapse($event, 'faqsContent_07')"
                    >
                      How can I contact World2Cover to enquire about travel
                      insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_07"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p class="text-gray">
                        For all customer service enquiries including existing
                        medical assessments you can easily go on our website
                        world2cover.com.au or call us on 02 9225 7599 - Our
                        hours are 8am to 9pm Monday to Friday & 9am to 6pm
                        Saturday and Sunday.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_08"
                      (click)="toggleCollapse($event, 'faqsContent_08')"
                    >
                      Who can get insured?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_08"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p><b>Cover is available to:</b></p>
                        <p>
                          Australian residents up to 75 years of age, provided:
                        </p>
                        <ul>
                          <li>
                            You purchase Your policy before You begin your trip;
                            and
                          </li>
                          <li>
                            for International cover Your trip begins and ends in
                            Australia; or
                          </li>
                          <li>
                            for Domestic cover Your trip must be wholly within
                            Australia.
                          </li>
                        </ul>
                        <p>
                          Temporary residents up to 75 years of age, provided:
                        </p>
                        <ul>
                          <li>
                            You hold a current Australian Visa (not a tourist,
                            study or working holiday visa) that will remain
                            valid beyond the period of Your return from Your
                            trip; and
                          </li>
                          <li>You hold a return ticket; and</li>
                          <li>
                            You have a primary place of residence in Australia
                            that You intend to return to; and
                          </li>
                          <li>
                            You purchase Your policy before You begin Your Trip;
                            and
                          </li>
                          <li>
                            for International cover Your trip begins and ends in
                            Australia; or
                          </li>
                          <li>
                            for Domestic cover Your trip must be wholly within
                            Australia.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_09"
                      (click)="toggleCollapse($event, 'faqsContent_09')"
                    >
                      What is a medical assessment?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_09"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p class="text-gray">
                        This is an online set of medical questions (assessment)
                        which you will need to complete if you wish to apply for
                        travel insurance but have Existing Medical Conditions
                        which are not automatically covered under the policy.
                        You can complete this as part of your travel insurance
                        quote at www.world2cover.com.au or call 02 9225 7599
                        Monday to Friday 8am-9pm and Saturday & Sunday 9am-6pm
                        for additional assistance. Upon completion of this
                        assessment we will inform you if the Existing Medical
                        Condition is covered and any additional premium payable.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_10"
                      (click)="toggleCollapse($event, 'faqsContent_10')"
                    >
                      What is an Existing Medical Condition?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_10"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          An Existing Medical Condition means any medical or
                          physical condition, disorder, disease, disability or
                          illness, including any
                          <strong><em>Mental Illness</em></strong
                          >, which <strong><em>You</em></strong> at the
                          <strong><em>Relevant Time</em></strong
                          >, were aware of, or a reasonable person in the
                          circumstances could be expected to have been aware of,
                          and the <strong><em>Relevant Time</em></strong
                          >:
                        </p>
                        <ol>
                          <li>
                            is chronic, ongoing, terminal, or has affected or
                            involved one or more of the following:
                            <ol type="a">
                              <li>
                                heart, circulatory system, lungs or respiratory
                                system, brain, kidneys, liver, or cancer;
                              </li>
                              <li>
                                surgery involving the back, neck, joints, or
                                abdomen; or
                              </li>
                            </ol>
                          </li>
                          <li>
                            In the last 12 months had:
                            <ol type="a">
                              <li>
                                presented symptoms which would have caused an
                                ordinarily prudent person to seek medical
                                opinion or treatment;
                              </li>
                              <li>become exacerbated or complicated; or</li>
                              <li>
                                been diagnosed, treated or treatment was
                                recommended by a
                                <em><strong>Treating Doctor</strong></em
                                >.
                              </li>
                            </ol>
                          </li>
                        </ol>
                        <p>
                          This definition applies to You, Your Travelling
                          Companion, a relative or any other person. If you
                          would like more information in relation to Existing
                          Medical Conditions, please read the PDS or call 02
                          9225 7599.
                        </p>
                        <p>
                          Capitalised terms used above are defined in the PDS
                          which can be viewed
                          <a href="{{ pdsLink }}" target="_blank">here</a>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_11"
                      (click)="toggleCollapse($event, 'faqsContent_11')"
                    >
                      Which existing medical conditions are covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_11"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          This section outlines those
                          <i><strong>Existing Medical Conditions</strong></i>
                          automatically included, where
                          <i><strong>You</strong></i
                          >, at the <i><strong>Relevant Time</strong></i
                          >:
                        </p>
                        <ul>
                          <li>
                            have not required hospitalisation or treatment by
                            any
                            <strong><i>Treating Doctor</i></strong> within the
                            last 12 months (unless a different time-period is
                            specifically listed in the list below) for the
                            <strong><i>Existing Medical Condition</i></strong
                            >;
                          </li>
                          <li>
                            are not awaiting the outcome of any investigation,
                            tests, surgery or other treatment for the
                            <strong><i>Existing Medical Condition</i></strong
                            >; and
                          </li>
                          <li>
                            meet any additional criteria set out in the
                            <i
                              >Existing Medical Conditions we automatically
                              cover</i
                            >
                            list below.
                          </li>
                        </ul>
                        <p>
                          <strong
                            >Please also read the “General Exclusions” section
                            of the PDS which can be viewed
                            <a href="{{ pdsLink }}" target="_blank">here</a
                            >.</strong
                          >
                        </p>
                        <p>
                          If the criteria above are satisfied, cover is
                          automatically included for the following
                          <strong><i>Existing Medical Conditions</i></strong
                          >:
                        </p>
                        <ol class="text-gray">
                          <li>Acne</li>
                          <li>
                            Allergies limited to Rhinitis, Chronic Sinusitis,
                            Eczema, Food Intolerance, Hay Fever, however this
                            excludes any Anaphylaxis as part of any such
                            condition
                          </li>
                          <li>
                            Asthma providing You:
                            <ol type="a">
                              <li>have no other lung disease; and</li>
                              <li>
                                are less than 60 years of age at the time You
                                purchased the policy
                              </li>
                            </ol>
                          </li>
                          <li>Bell’s Palsy</li>
                          <li>Benign Positional Vertigo</li>
                          <li>Bunions</li>
                          <li>Carpal Tunnel Syndrome</li>
                          <li>Cataracts</li>
                          <li>Coeliac Disease</li>
                          <li>Congenital Blindness</li>
                          <li>Congenital Deafness</li>
                          <li>
                            Diabetes Mellitus (Type 1) providing You:
                            <ol type="a">
                              <li>were diagnosed over 12 months ago; and</li>
                              <li>
                                have no eye, kidney, nerve or vascular
                                complications; and
                              </li>
                              <li>
                                do not also suffer from a known cardiovascular
                                disease, Hypertension or Hypercholesterolaemia;
                                and
                              </li>
                              <li>
                                are under 50 years of age at the date of policy
                                purchase
                              </li>
                            </ol>
                          </li>
                          <li>
                            Diabetes Mellitus (Type 2) providing You:
                            <ol type="a">
                              <li>were diagnosed over 12 months ago; and</li>
                              <li>
                                have no eye, kidney, nerve or vascular
                                complications; and
                              </li>
                              <li>
                                do not also suffer from a known cardiovascular
                                disease, Hypertension or Hypercholesterolaemia;
                                and
                              </li>
                              <li>
                                are under 50 years of age at the date of policy
                                purchase
                              </li>
                            </ol>
                          </li>
                          <li>Dry Eye Syndrome</li>
                          <li>
                            Epilepsy providing:
                            <ol type="a">
                              <li>
                                there has been no change to Your medication
                                regime in the past 12 months and
                              </li>
                              <li>
                                You are on no more than one anticonvulsant
                                medication
                              </li>
                            </ol>
                          </li>
                          <li>Gastric Reflux</li>
                          <li>Gastric/Peptic Ulcer</li>
                          <li>Glaucoma</li>
                          <li>Gout</li>
                          <li>Graves’ Disease</li>
                          <li>Hiatus Hernia</li>
                          <li>
                            Hip/Knee replacement if performed more than 12
                            months ago but less than 10 years ago
                          </li>
                          <li>
                            Hypercholesterolaemia (High Cholesterol) providing
                            You do not also suffer from a known cardiovascular
                            disease and/or Diabetes
                          </li>
                          <li>
                            Hyperlipidaemia (High Blood Lipids) providing You do
                            not also suffer from a known cardiovascular disease
                            and/or Diabetes
                          </li>
                          <li>
                            Hypertension (High Blood Pressure) providing You do
                            not also suffer from a known cardiovascular disease
                            and/or Diabetes
                          </li>
                          <li>Hypothyroidism, including Hashimoto’s Disease</li>
                          <li>Incontinence</li>
                          <li>Insulin Resistance</li>
                          <li>Macular Degeneration</li>
                          <li>Meniere’s Disease</li>
                          <li>Migraine</li>
                          <li>Nocturnal Cramps</li>
                          <li>Plantar Fasciitis</li>
                          <li>Raynaud’s Disease</li>
                          <li>Sleep Apnoea</li>
                          <li>Solar Keratosis</li>
                          <li>Trigeminal Neuralgia</li>
                          <li>Trigger Finger</li>
                        </ol>

                        <p>
                          If your existing medical condition meets the
                          automatically covered conditions criteria, there is
                          nothing further you need to do in order to be covered
                          for that condition.
                        </p>
                        <p>
                          If your existing medical condition does not meet the
                          automatically covered conditions criteria, and you
                          want cover for this, you must complete a medical
                          assessment to assess whether we can accept this risk
                          and what, if any additional premium is payable by you,
                          to have that condition specified on your policy.
                        </p>
                        <p>
                          <strong
                            >Please also read the “General Exclusions” section
                            of the PDS which can be viewed
                            <a href="{{ pdsLink }}" target="_blank">here</a
                            >.</strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_12"
                      (click)="toggleCollapse($event, 'faqsContent_12')"
                    >
                      I have an existing medical condition that isn’t
                      automatically covered or does not meet the criteria. Can I
                      still get cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_12"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          If <strong><i>You</i></strong> have an
                          <strong><i>Existing Medical Condition</i></strong>
                          that is not automatically covered above and
                          <strong><i>You</i></strong> want cover for this
                          <strong
                            ><i>Existing Medical Condition, You</i></strong
                          >
                          will need to complete
                          <strong><i>Our</i></strong> online medical assessment
                          so that <strong><i>We</i></strong> can assess whether:
                        </p>
                        <ol type="a">
                          <li>
                            <strong><i>We</i></strong> can cover the
                            <strong><i>Existing Medical Condition</i></strong> –
                            in which case additional
                            <i><strong>Premium</strong></i> may be payable and
                            the
                            <strong><i>Existing Medical Condition</i></strong>
                            will be listed on
                            <strong><i>Your Certificate of Insurance</i></strong
                            >; or
                          </li>
                          <li>
                            <strong><i>We</i></strong> can’t cover the
                            <strong><i>Existing Medical Condition</i></strong> –
                            in which case, the
                            <strong><i>Existing Medical Condition</i></strong>
                            that has not been accepted will be listed on
                            <strong><i>Your Certificate of Insurance</i></strong
                            >.
                          </li>
                        </ol>
                        <p>
                          You will need to complete an online medical
                          assessment. You can complete this as part of Your
                          travel insurance quote at www.world2cover.com.au or
                          call (02) 9225 7599 for additional assistance.
                        </p>
                        <p>
                          <strong>
                            Please also read the General Exclusions which apply
                            in addition to any limitations set out above.
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_13"
                      (click)="toggleCollapse($event, 'faqsContent_13')"
                    >
                      Am I covered if I'm pregnant?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_13"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        <strong><i>Our</i></strong> policies provide cover for
                        pregnancy in limited circumstances.
                      </p>
                      <p><strong>What is covered</strong></p>
                      <p>
                        Cover is included automatically up to the end of the
                        25th week of pregnancy for:
                      </p>
                      <ol class="text-gray">
                        <li>Single non-complicated pregnancies;</li>
                        <li>unexpected pregnancy complications; and</li>
                        <li>
                          childbirth which was accelerated by accidental injury
                          in limited circumstances.
                        </li>
                      </ol>
                      <p>Please see further information below.</p>
                      <p>
                        <strong><i>You</i></strong> will need to apply for cover
                        if at the <strong><i>Relevant Time</i></strong
                        >, You know <strong><i>You</i></strong> are pregnant and
                        are aware of, or a reasonable person in the
                        circumstances could be expected to have been aware of,
                        any of the following:
                      </p>
                      <ol class="text-gray" type="i">
                        <li>
                          there have been complications with this or a previous
                          pregnancy,
                        </li>
                        <li>
                          <i><strong>You</strong></i> are expecting a multiple
                          pregnancy (such as triplets or twins), or
                        </li>
                        <li>
                          <i><strong>You</strong></i> have an
                          <i><strong>Existing Medical Condition</strong></i>
                          which could have an adverse impact on
                          <i><strong>Your</strong></i> health.
                        </li>
                      </ol>
                      <p><strong>Pregnancy Complications</strong></p>
                      <p>
                        Pregnancy complications are considered
                        <strong><i>Existing Medical Conditions</i></strong> and
                        need to be disclosed and assessed if
                        <strong><i>You</i></strong> want cover for these
                        conditions whilst on <strong><i>Your Trip</i></strong
                        >. Pregnancy complications include those that occur
                        during pregnancy or may be caused by
                        <strong><i>Existing Medical Conditions</i></strong> that
                        already existed prior to the pregnancy, such as previous
                        high risk of miscarriage, gestational diabetes,
                        hyperemesis (severe morning sickness) or pre-eclampsia.
                        Please refer to the
                        <i>Existing Medical Conditions We need to assess</i>
                        section above.
                      </p>
                      <p><strong>What is not covered</strong></p>
                      <p>There is no cover for:</p>
                      <ul>
                        <li>
                          pregnancy complications occurring from the 26th week
                          of gestation , or as described in the section above
                          (<strong>Pregnancy Complications</strong>) unless such
                          complications are specifically accepted by
                          <i><strong>Us</strong></i> and noted on
                          <i><strong>Your Certificate of Insurance</strong></i
                          >;
                        </li>
                        <li>
                          childbirth at any stage of the pregnancy, other than
                          as a result of an
                          <i><strong>Accident</strong></i> occurring prior to
                          the end of the 25th week of
                          <strong><i>Your</i></strong> pregnancy which causes
                          <strong><i>You</i></strong> to give birth prematurely;
                        </li>
                        <li>
                          the health or care of a newborn child, irrespective of
                          the stage of pregnancy when the child is born; or
                        </li>
                        <li>regular antenatal care.</li>
                      </ul>
                      <p>
                        <strong><i>We</i></strong> recommend that
                        <strong><i>You</i></strong> contact
                        <strong><i>Your</i></strong> Treating Doctor and obtain
                        written confirmation that
                        <strong><i>You</i></strong> are fit to travel before
                        commencing <strong><i>Your</i></strong> planned
                        <strong><i>Trip</i></strong
                        >. Please see the Changes in
                        <strong><i>Your</i></strong> health section below, which
                        outlines conditions to
                        <strong><i>Our</i></strong> cover.
                      </p>
                      <p>
                        If <strong><i>You</i></strong> are unsure whether
                        <strong><i>You</i></strong> need to complete a medical
                        assessment for <strong><i>Your</i></strong> pregnancy,
                        please call
                        <a href="tel:0292257599">02 9225 7599</a> for additional
                        assistance.
                      </p>
                      <p>
                        Please refer to <i>‘General Exclusions’</i> in the PDS
                        which apply to all sections of cover.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_14"
                      (click)="toggleCollapse($event, 'faqsContent_14')"
                    >
                      What happens if I develop a medical condition after I buy
                      travel insurance but before I leave on my trip. Would I
                      still be covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_14"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If before starting
                          <i><strong>Your Trip You</strong></i> become aware of,
                          or a reasonable person in the circumstances could be
                          expected to have become aware of, a change in
                          <i><strong>Your</strong></i> health, that:
                        </p>
                        <ul>
                          <li>
                            has, or is likely to have, an effect on the
                            diagnosis, severity, or management of an
                            <strong><i>Existing Medical Condition</i></strong
                            >, such as a change in treatment, medication or
                            dosage, a new symptom or change of a condition of an
                            <strong>Existing Medical Condition</strong>, that
                            <strong>We</strong> have accepted on Your
                            <strong>Certificate of Insurance</strong>; or
                          </li>
                          <li>
                            An
                            <i><strong>Existing Medical Condition</strong></i>
                            manifests itself, that is not otherwise covered
                            under the ‘Medical Conditions We automatically
                            cover’ section;
                          </li>
                        </ul>
                        <p>
                          <strong><i>You</i></strong> must tell
                          <strong><i>Us</i></strong> as soon as reasonably
                          practicable and prior to starting
                          <strong><i>Your Trip</i></strong
                          >.
                        </p>
                        <p>
                          <strong><i>We</i></strong> will then assess the
                          condition to determine if
                          <strong><i>We</i></strong> can offer
                          <strong><i>You</i></strong> cover, as outlined in the
                          <i>‘Existing Medical Conditions We need to assess’</i>
                          section above. If <strong><i>We</i></strong> consider
                          any change in health no longer satisfies the criteria
                          <strong><i>We</i></strong> offer under the policy
                          because it substantially increases the risk of loss,
                          damage, illness, injury or liability, then
                          <strong><i>We</i></strong> will let you know and
                          decline or withdraw coverage, as applicable, by
                          issuing <strong><i>You</i></strong> an updated
                          <strong><i>Certificate of Insurance</i></strong> with
                          any applicable changes to
                          <strong><i>Existing Medical Conditions</i></strong>
                          that are or are not covered by the policy.
                        </p>
                        <p>
                          If <strong><i>We</i></strong> withdraw or decline
                          cover and <strong><i>You</i></strong> decide to cancel
                          <strong><i>Your Trip</i></strong> as a result, cover
                          may still be available under
                          <i>‘Section 1 Cancellation Fees and Lost Deposits’</i
                          >. If <strong><i>You</i></strong> do not claim and
                          want to alternatively cancel
                          <strong><i>Your</i></strong> policy prior to starting
                          <strong><i>Your Trip</i></strong> as a result of this
                          decision, <strong><i>We</i></strong> will refund your
                          premium in full, even if this is outside the cooling
                          off period.
                        </p>
                        <p>
                          If <strong><i>You</i></strong> fail to tell
                          <strong><i>Us</i></strong> about a change in
                          <strong><i>Your</i></strong> health as set out above,
                          <strong><i>We</i></strong> may refuse coverage or
                          refuse to pay <strong><i>Your</i></strong> claim, in
                          whole or in part, subject to our rights under section
                          54 of the
                          <i>Insurance Contracts Act 1984 (Clth)</i> to the
                          extent that <strong><i>We</i></strong> have been
                          prejudiced as a result of your failure to notify
                          <strong><i>Us. We</i></strong> may also be entitled to
                          cancel <strong><i>Your</i></strong> policy under
                          section 60 of the Insurance Contracts Act (Clth).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_15"
                      (click)="toggleCollapse($event, 'faqsContent_15')"
                    >
                      Will my children be covered by my insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_15"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Cover is included for your dependent children,
                          grandchildren, step-children and foster children, at
                          no additional cost (provided they do not require cover
                          for an existing medical condition), and they meet the
                          following criteria:
                        </p>
                        <ul>
                          <li>
                            aged 25 years or younger at the time You buy Your
                            policy;
                          </li>
                          <li>
                            financially dependent on their parents or
                            grandparents and not working full time;
                          </li>
                          <li>travelling with You for Your entire Trip;</li>
                          <li>
                            listed on the Certificate of Insurance as Your
                            Dependant; and
                          </li>
                          <li>
                            whilst on Your Trip, is dependent on an Adult listed
                            on Your Certificate of Insurance.
                          </li>
                        </ul>
                        <p>
                          If they meet the above criteria and you choose our Ski
                          and Winter Sports option or our Cruise option, they’ll
                          be covered for this as well. If you have omitted to
                          list them, please contact us as soon as possible on 02
                          9225 7599, Mon-Fri 8am to 9pm, Saturday to Sunday 9am
                          to 6pm or email us at
                          <a href="mailto:travelservice@world2cover.com.au"
                            >travelservice&#64;world2cover.com.au</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_16"
                      (click)="toggleCollapse($event, 'faqsContent_16')"
                    >
                      What policy benefits and limits are applicable for my
                      Dependents (children, grandchildren, step-children and
                      foster children)?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_16"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Unless the benefits table show the sum insured per
                          person, the limit payable for your dependents is
                          included in the total combined sum insured for each
                          listed adult. For example, if an adult has Top cover
                          on a single policy and a claim is made under Section
                          13 Travel Delay, the maximum amount that may be paid
                          for one adult and their dependent’s combined is
                          $3,000, while a policy with two adults listed with
                          their dependents would have a total combined limit of
                          $6,000. Please note there are other sections of the
                          policy whereby the limits are itemised per person
                          which includes dependent’s individually e.g. funeral
                          expenses in section 2G. Please refer to the Product
                          Disclosure Statement for further details.
                        </p>
                        <p>
                          Dependent children will be covered for no additional
                          charge if they meet the following criteria:
                        </p>
                        <ul>
                          <li>
                            aged 25 years or younger at the time You buy Your
                            policy;
                          </li>
                          <li>
                            financially dependent on their parents or
                            grandparents and not working full time;
                          </li>
                          <li>travelling with You for Your entire Trip;</li>
                          <li>
                            listed on the Certificate of Insurance as Your
                            Dependant; and
                          </li>
                          <li>
                            whilst on Your Trip, is dependent on an Adult listed
                            on Your Certificate of Insurance.
                          </li>
                        </ul>
                        <p>
                          A dependent can be a child of any Adult listed on Your
                          certificate of Insurance.
                        </p>
                        <p>
                          The limit for any benefit payable for dependents is
                          included in the insured adult's sum insured. For
                          example, if an adult has Top cover on a single policy
                          and a claim is made under Section 13 Travel Delay, the
                          maximum amount that may be paid for 1 adult and their
                          children combined is $3,000. Please note there are
                          other sections of the policy whereby the limits are
                          itemised per person which includes dependents
                          individually e.g. Funeral expenses in section 2G.
                          Please refer to the
                          <a href="{{ pdsLink }}" target="_blank"
                            >Product Disclosure Statement</a
                          >
                          for further details.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_17"
                      (click)="toggleCollapse($event, 'faqsContent_17')"
                    >
                      Do I need to list all countries I will be travelling to
                      when I purchase my policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_17"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Yes, you should list all countries to confirm that cover
                        is available for the countries you are travelling to.
                        You should also check
                        <a
                          rel="noopener noreferrer"
                          href="https://www.smartraveller.gov.au/"
                          target="_blank"
                          >www.smarttraveller.gov.au</a
                        >
                        as we do not provide cover for any country or region
                        which is the subject of a “Do Not Travel“ advisory
                        issued by DFAT prior to you purchasing your policy.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_18"
                      (click)="toggleCollapse($event, 'faqsContent_18')"
                    >
                      Which policy do I select if I am going on a cruise?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_18"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Cover for international and domestic river cruising is
                          included automatically in the policy and you do not
                          need to select the Cruise option to be covered.
                        </p>
                        <p>
                          If you are going on an ocean Cruise which stops at
                          other countries, you will need to select International
                          Cover and select each country where the cruise stops.
                        </p>
                        <p>
                          All international cruising will require you to select
                          the Cruise option to be covered while you are on your
                          cruise. An extra premium is payable for cruise cover.
                        </p>
                        <p>
                          If you are going on an Ocean cruise which is only in
                          Australian waters or calling into ports in Australia
                          you still require a policy which includes medical
                          cover whilst you are on board the ship, as many
                          on-board medical providers on cruises are not
                          registered with Medicare (check with your cruise
                          provider). You will need to select Australian Cruises
                          as your destination. This will then provide you
                          medical and evacuation cover whilst at sea, but not if
                          you go to a medical provider whilst in port in
                          Australia. Please refer to the
                          <a href="{{ pdsLink }}" target="_blank"
                            >Product Disclosure Statement</a
                          >
                          for further details.
                        </p>
                        <p>
                          Please note that no medical cover is included within
                          our Domestic policy and we cannot cover medical costs
                          in Australia. Therefore, the Domestic policy is not
                          suitable for cruises if you require cover for on board
                          medical treatment and evacuation.
                        </p>
                        <p style="margin-top: 15px">
                          An extra premium is payable for the Cruise cover
                          option.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_19"
                      (click)="toggleCollapse($event, 'faqsContent_19')"
                    >
                      When do I receive my policy documents?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_19"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        When you buy your policy, we will email your policy
                        documents to you. Your documents include your
                        Certificate of Insurance and your
                        <a href="{{ pdsLink }}" target="_blank"
                          >World2Cover Travel Insurance Combined Financial
                          Services Guide and Product Disclosure Statement</a
                        >. It’s a good idea to carry a copy of your policy
                        documents with you when you travel. It is also a good
                        idea to keep them in your email inbox so you can access
                        them if you need to.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_20"
                      (click)="toggleCollapse($event, 'faqsContent_20')"
                    >
                      When does my policy start?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_20"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Depending on the level of cover selected, cover
                        commences from the date of purchase for the following
                        benefits (if available): Section 1: Cancellation Fees
                        and Lost deposits and Section 10 Financial Default . All
                        other benefits commence on the date your trip commences,
                        as stated on your certificate of insurance.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_21"
                      (click)="toggleCollapse($event, 'faqsContent_21')"
                    >
                      I made a mistake when purchasing my policy, how do I fix
                      it?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_21"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Please contact us on 02 9225 7599, Mon-Fri 8m to 9pm,
                        Saturday to Sunday 9am to 6pm as soon as you realise the
                        error so that we can review and arrange the amendments
                        where possible. You can also advise us by email at
                        <a
                          href="mailto:travelservice@world2cover.com.au"
                          style="
                            color: #32b8c9;
                            background-color: #ffffff;
                            border-bottom: 0px;
                          "
                          >travelservice&#64;world2cover.com.au</a
                        >
                        with full details of the error and the correct details
                        as per your policy number.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_22"
                      (click)="toggleCollapse($event, 'faqsContent_22')"
                    >
                      What happens if I change my mind and want to cancel the
                      policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_22"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          <strong
                            >Cancelling within the cooling-off period</strong
                          >
                        </p>
                        <p>
                          You have 21 days from the day You purchase Your policy
                          to decide if the cover is right for You and suits Your
                          needs. If the policy does not meet Your needs You can
                          cancel Your policy within this “cooling-off period”
                          for a full refund, provided You:
                        </p>
                        <ul>
                          <li>Haven’t started Your Trip; and/or</li>
                          <li>Haven’t made a claim; and/or</li>
                          <li>
                            Don’t intend to make a claim or exercise any other
                            rights under Your policy.
                          </li>
                        </ul>
                        <p style="margin-top: 15px">
                          Simply contact Us on 13 13 29 within the cooling-off
                          period and We can arrange this for You.
                        </p>
                        <p>
                          <strong
                            >Cancellation outside the cooling-off period</strong
                          >
                        </p>
                        <p>
                          If You would like to cancel Your policy outside the
                          cooling-off period then You can request Us to consider
                          this, provided You:
                        </p>
                        <ul>
                          <li>Haven’t started Your Trip; and/or</li>
                          <li>Haven’t made a claim; and/or</li>
                          <li>
                            Don’t intend to make a claim or exercise any other
                            rights under Your policy.
                          </li>
                        </ul>
                        <p style="margin-top: 15px">
                          We will consider Your request and may at Our
                          discretion provide You with a pro-rated refund. This
                          refund (and Our decision in providing You with a
                          refund which will not be unreasonably withheld) will
                          be based on numerous factors including:
                        </p>
                        <ul>
                          <li>The level of cover/policy type chosen; and/or</li>
                          <li>
                            The date You purchased Your Policy and the date the
                            Trip would have begun; and/or
                          </li>
                          <li>Any other extenuating circumstances.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_23"
                      (click)="toggleCollapse($event, 'faqsContent_23')"
                    >
                      How do I extend my policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_23"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          You can extend your policy under certain conditions.
                        </p>
                        <p></p>
                        <p>
                          We will extend the term of Your cover for no
                          additional cost if any delay is due to a reason which
                          is covered under Your policy
                        </p>
                        <p>
                          For other reasons you can apply to extend your Single
                          Trip Policy by phoning us on 02 9225 7599 or sending
                          an email to
                          <a href="mailto:travelservice@world2cover.com.au"
                            >travelservice&#64;world2cover.com.au</a
                          >
                          as soon as practicable prior to your original policy
                          expiry date. Extension of cover needs our written
                          approval and you will need to pay any applicable extra
                          premium, if we agree to extend cover. We will issue a
                          new Certificate of Insurance. The period of insurance
                          on your new Certificate of Insurance combined with
                          your previous period of insurance, cannot be longer
                          than a combined maximum period of 12 months.
                          Applications to extend cover are subject to additional
                          conditions – please refer the
                          <a href="{{ pdsLink }}" target="_blank"
                            >Product Disclosure Statement</a
                          >
                          for further details.
                        </p>
                        <p>You cannot extend your cover:</p>
                        <ul>
                          <li>
                            For any existing medical conditions, unless they are
                            listed in the
                            <a href="{{ pdsLink }}" target="_blank">PDS</a> and
                            you haven’t been hospitalised (including Day Surgery
                            or Emergency attendance) in the past 12 months; or
                            unless declared, accepted and You have paid the
                            appropriate Premium; or
                          </li>
                          <li>
                            For any condition you suffered during the term of
                            your original policy; and
                          </li>
                          <li>
                            Where you have not advised us of any circumstances
                            that has given (or may give) rise to a claim under
                            your original Policy; or
                          </li>
                          <li>Under our Annual Multi-Trip plan</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_24"
                      (click)="toggleCollapse($event, 'faqsContent_24')"
                    >
                      What is an excess?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_24"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If you make a claim you may be required to pay an
                          excess. An excess is an agreed dollar amount that is
                          subtracted from each and every Insured Event. – see
                          the definitions of ‘Excess’ and ‘Insured Event’ in the
                          PDS for more information. Our standard excess is $200.
                          If you purchase the International Single Trip Top or
                          Essential cover, the Annual Multi-Trip or Domestic
                          Cover policy you can reduced the excess to $100 or $0,
                          for an increased premium at the time of purchase. If
                          you purchase the International Single Trip Basics
                          cover, you can only reduce the excess to $100, for an
                          increased premium at the time of purchase.
                        </p>
                        <p>
                          Your excess will be shown on your certificate of
                          insurance.
                        </p>
                        <p>
                          Please see the applicable
                          <a href="{{ pdsLink }}" target="_blank"
                            >Product Disclosure Statement</a
                          >
                          for more information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_25"
                      (click)="toggleCollapse($event, 'faqsContent_25')"
                    >
                      What is unattended luggage?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_25"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Unattended means but is not limited to, when an item
                          is not on Your person or under Your control, or the
                          control of Your Travelling Companion, at the time of
                          the loss, theft or damage, or left in a position where
                          it can be taken or damaged without Your or Your
                          Travelling Companion’s knowledge, including on the
                          beach or beside the pool while You swim, in a Public
                          Place or leaving it where You or Your Travelling
                          Companion are unable to prevent it from being
                          unlawfully taken or damaged. Unattended also means
                          leaving an item behind, forgetting the item, walking
                          away from it, or leaving it in a Public Place.
                        </p>
                        <p>
                          For the full list of exclusions, please read the
                          <a href="{{ pdsLink }}" target="_blank"
                            >Product Disclosure Statement</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_26"
                      (click)="toggleCollapse($event, 'faqsContent_26')"
                    >
                      Is there a maximum trip duration for an Annual Multi-Trip
                      policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_26"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        The maximum days allowed per trip is either 30, 45 or 60
                        days, depending on the policy you choose. You must
                        select the appropriate number of days that will cover
                        any single trip you may take during the 12-month period
                        at the time of purchase. The days allowed per trip may
                        be able to be increased to a longer duration during the
                        period of the policy and an additional premium will
                        apply.
                      </p>
                      <p>
                        Please contact us for assistance at 02 9225 7599 - Our
                        hours are 8am to 9pm Monday to Friday &amp; 9am to 6pm
                        Saturday and Sunday.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_27"
                      (click)="toggleCollapse($event, 'faqsContent_27')"
                    >
                      Is there a limit to the amount of trips I can take on an
                      Annual Multi-Trip policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_27"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        You are covered for an unlimited number of trips over a
                        12-month period, provided that each trip is at least
                        250km from your home and less than the days selected per
                        trip (30, 45 or 60 days).
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_28"
                      (click)="toggleCollapse($event, 'faqsContent_28')"
                    >
                      Does my Annual Multi-Trip policy cover me domestically?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_28"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Your Annual Multi-Trip policy covers you for
                        international trips as well as trips within Australia
                        where the trip is more than 250km from your home up to
                        the maximum trip days selected. If your trip is in
                        Australia, you are not covered for medical, hospital or
                        dental expenses. Other exclusions may also apply. Please
                        read the PDS for further information which can be viewed
                        <a href="{{ pdsLink }}" target="_blank">here</a>.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_29"
                      (click)="toggleCollapse($event, 'faqsContent_29')"
                    >
                      Are all benefits payable per policy or per insured adult
                      on an Annual Multi-Trip policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_29"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Most of your policy benefits are per insured adult,
                        however you should read the
                        <a href="{{ pdsLink }}" target="_blank"
                          >Product Disclosure Statement</a
                        >
                        for further information. The limits are reinstated after
                        each trip.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_30"
                      (click)="toggleCollapse($event, 'faqsContent_30')"
                    >
                      Can people with a joint Annual Multi-Trip policy travel
                      alone for a period of time and still be covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_30"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Insured adults on the policy can travel alone on a
                          trip. Dependents who are covered under the policy must
                          travel with an insured adult for the whole trip.
                        </p>
                        <p>
                          In respect of any Annual Multi-Trip plans means the
                          travel You are undertaking and commences from the time
                          You leave Your Home or place of departure to start
                          Your Trip until You return Home or until the end of
                          the Period of Insurance shown on the Certificate of
                          Insurance, whichever is sooner. The length of any one
                          Trip cannot exceed 30,45 or 60 days (depending on the
                          plan chosen) and must be at least 250km from Your
                          Home.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_31"
                      (click)="toggleCollapse($event, 'faqsContent_31')"
                    >
                      What is the emergency phone number to call while I am
                      travelling?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_31"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If You have an overseas medical emergency, our medical
                          Emergency Assistance team is available 24 hours a day,
                          365 days a year to take your call:
                        </p>
                        <p><strong>While travelling in Japan:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:08008009117">0800-800-9117</a></strong
                          >
                          (toll free) or if mobile access is restricted call
                          <strong
                            ><a href="tel:0362285881">03 6228 5881</a></strong
                          >
                          (local number in Japan)
                        </p>
                        <p><strong>For other overseas destinations:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:+61280551683"
                              >+61 2 8055 1683</a
                            ></strong
                          >
                          (reverse charges accepted from the overseas operator)
                          while travelling in all other countries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_32"
                      (click)="toggleCollapse($event, 'faqsContent_32')"
                    >
                      Do you cover medical expenses in Australia?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_32"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        No, our policies cover reasonable overseas medical costs
                        only and there is no medical, hospital or dental cover
                        on a Domestic policy or for medical expenses incurred in
                        Australia, or on an International policy following your
                        return to Australia. As a general insurer we are unable
                        to pay medical costs in Australia and this includes any
                        gap payments on any Medicare or private health insurer
                        items
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_33"
                      (click)="toggleCollapse($event, 'faqsContent_33')"
                    >
                      How do I make a claim?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_33"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Claims can be lodged either online at
                          <a routerLink="/how-to-claim"
                            >world2cover.com.au/how-to-claim</a
                          >
                          under the “how to claim“ tab, by phone, or by email.
                          If lodging by phone or email the applicable claim form
                          will be provided for completion together with details
                          of the documentation that needs to be provided.
                        </p>
                        <p>
                          Providing the information needed helps Us to make a
                          timely and accurate decision about Your claim. You can
                          contact Us either during your trip or once you have
                          returned and we will guide you through the process. We
                          will not be able to process Your claim unless you
                          provide Us with all of the necessary information. Full
                          details should be submitted within 30 days of your
                          return.
                        </p>
                        <p>
                          For all claims, evidence of the medical condition
                          treated, incident or loss must be supported by the
                          relevant documentation e.g. police report, medical
                          report, receipts, proof of ownership etc. If you
                          cannot provide it, then We may reduce or refuse to pay
                          your claim. Any costs or expenses associated with
                          obtaining these items documents will be at Your own
                          cost.
                        </p>
                        <p>
                          <strong>Web:</strong>
                          <a routerLink="/how-to-claim"
                            >world2cover.com.au/how-to-claim</a
                          >
                        </p>
                        <p>
                          <strong>Phone:</strong
                          ><a href="tel:0292257599"> 02 9225 7599</a>
                        </p>
                        <p>
                          <strong>Email:</strong>
                          <a href="mailto:travelclaims@world2cover.com.au"
                            >travelclaims&#64;world2cover.com.au</a
                          >
                        </p>
                        <p>
                          <strong>Mail:</strong><br />
                          World2Cover Claims<br />
                          GPO Box 4616<br />
                          Sydney 2001
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_34"
                      (click)="toggleCollapse($event, 'faqsContent_34')"
                    >
                      What happens if I get sick or injured overseas, but don't
                      feel it's an emergency?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_34"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If you are not hospitalised or you are being treated
                          as an outpatient and the total cost of any
                          consultation or treatment will exceed AUD $2,000, you
                          or a member of your travelling party, should contact
                          us as soon as reasonably <span>practicable</span>. If
                          you do not contact us, and incur costs
                          <span>without </span>our consent, we may limit the
                          amount payable under a claim, to the amount we would
                          have paid towards any expenses (including medical) or
                          for any evacuation/repatriation or airfares that have
                          not been approved or arranged by us..
                        </p>
                        <p>
                          You will need to keep all your medical reports and
                          receipts from the doctors and or hospital. These
                          documents will be needed to support your claim when it
                          is lodged.
                        </p>
                        <p>
                          Our medical emergency team is available 24 hours a
                          day, 365 days a year to take your call.
                        </p>
                        <p><strong>While travelling in Japan:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:08008009117">0800-800-9117</a></strong
                          >
                          (toll free) or if mobile access is restricted call
                          <strong
                            ><a href="tel:0362285881">03 6228 5881</a></strong
                          >
                          (local number in Japan)
                        </p>
                        <p><strong>For other overseas destinations:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:+61280551683"
                              >+61 2 8055 1683</a
                            ></strong
                          >
                          (reverse charges accepted from the overseas operator)
                          while travelling in all other countries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_35"
                      (click)="toggleCollapse($event, 'faqsContent_35')"
                    >
                      If I make a claim, what do I need to do when I return
                      home?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_35"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          For all claims You must let Us know of your claim as
                          soon as possible, full details should be submitted
                          within 30 days after your return Home either by:
                        </p>
                        <ul>
                          <li>
                            Online claim form on the
                            <a href="/How-to-Claim">How to Claim</a> tab on our
                            website, or
                          </li>
                          <li>
                            Calling Us on +612 9225 7599 - Our hours are 8am to
                            8pm Monday to Friday &amp; 9am to 6pm Saturday and
                            Sunday, or,
                          </li>
                          <li>
                            Emailing Us at
                            <a href="mailto:travelclaims@world2cover.com.au"
                              >travelclaims&#64;world2cover.com.au</a
                            >, or,
                          </li>
                          <li>
                            Writing to Us at World2Cover Claims, GPO Box 4616,
                            Sydney NSW 2001.
                          </li>
                        </ul>
                        <p style="margin-top: 10px">
                          Once we have received the notification of your claim,
                          we will advise you what information we require and
                          guide you through the claims process.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_36"
                      (click)="toggleCollapse($event, 'faqsContent_36')"
                    >
                      Who do I contact if I get sick or injured while travelling
                      overseas?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_36"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If you have an overseas medical emergency, you should
                          contact our Emergency Assistance team for help. Our
                          team is available 24 hours a day, 365 days a year to
                          take your call.
                        </p>
                        <p><strong>While travelling in Japan:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:08008009117">0800-800-9117</a></strong
                          >
                          (toll free) or if mobile access is restricted call
                          <strong
                            ><a href="tel:0362285881">03 6228 5881</a></strong
                          >
                          (local number in Japan)
                        </p>
                        <p><strong>For other overseas destinations:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:+61280551683"
                              >+61 2 8055 1683</a
                            ></strong
                          >
                          (reverse charges accepted from the overseas operator)
                          while travelling in all other countries.
                        </p>
                        <p>
                          If You are hospitalised, You, or a member of Your
                          travelling party, must contact Us as soon as possible.
                          If You do not, then to the extent permissible by law,
                          We will not pay for any expenses or for any
                          evacuation/repatriation or airfares that have not been
                          approved or arranged by Us.
                        </p>
                        <p>
                          If you are not hospitalised but you are being treated
                          as an outpatient and the total cost of any treatment
                          will exceed AUD $2,000, you must contact us. We will
                          not pay for any expenses that they have not approved.
                        </p>
                        <p>
                          You will need to keep all your medical reports and
                          receipts from the doctors and or hospital. These
                          documents will be needed to support your claim when it
                          is lodged.
                        </p>
                        <p>
                          It is always a good idea to register your details with
                          Smart Traveller before your depart, at
                          <a
                            rel="noopener noreferrer"
                            href="https://www.smartraveller.gov.au/"
                            target="_blank"
                            >www.smarttraveller.gov.au</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_37"
                      (click)="toggleCollapse($event, 'faqsContent_37')"
                    >
                      Who do I contact if I need an interpreter?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_37"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <h4>Interpreting Services</h4>
                        <p>
                          We are pleased to offer assistance in the following
                          ways if you need help understanding any aspect of your
                          travel insurance policy or claim in your language.
                        </p>
                        <h4>Contact us by phone</h4>
                        <p>
                          Our team has access to many languages to assist you
                          directly. Call us on (02) 9225 7599
                        </p>
                        <h4>Contact Translating and Interpreting Services</h4>
                        <p>
                          Translating and Interpreting Services (TIS) are
                          available 24/7 and their interpreters speak over 160
                          languages. They're available over the phone and in
                          person.
                        </p>
                        <p>
                          Book an
                          <a
                            href="https://www.tisnational.gov.au/"
                            target="_blank"
                            >appointment with TIS</a
                          >, or call them on 131 450. This is a free service.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
